console.log('custom');


if (module.hot) {

    var hotEmitter = require("webpack/hot/emitter");
    hotEmitter.on("webpackHotUpdate", function(currentHash) {
        const styles = document.getElementsByClassName('hmr-reload');
        for (let style of styles)
            style.href = style.href.replace(/(([\?&])(v=\d+)?)$/,`$2v=${Date.now()}`)
    })

    window.addEventListener('DOMContentLoaded', (event) => {
        let links = document.getElementsByTagName("link");
        for (let link of links) {

            if(link.rel && link.rel === 'stylesheet')
                if (link.href.match('127.0.0.1:3019') ) {
                    link.href =  link.href + (link.href.indexOf('?') !== -1 ? '&' : '?')
                        + `v=${Date.now()}`;
                    link.classList.add("hmr-reload")
                }
        }

    });
}

$(document).ready(function() {
    setTimeout(function() {
        if (window.matchMedia('(max-width: 991px)').matches) {
            $('.navbar-nav.megamenu .dropdown-toggle.has-category .caret').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                var parent = $(this).closest('.dropdown');
                parent.toggleClass('active');
                parent.find('.dropdown-menu.level1').toggle();
            });
        }
    }, 500);
});